import PropTypes from 'prop-types'
import ButtonCta from '@components/atoms/button-cta'
import renderLink from '@components/renderers/render-link'
import ButtonLink from '@components/atoms/button-link'
import useStyles from '@components/leaderboard/leaderboard.style-hook'

const ButtonViewLeaderboard = (props) => {
  const { headline, slug, externalUrl } = props
  if (!slug && !externalUrl) return null
  const linkProps = renderLink({ slug, type: 'Leaderboard' })
  let viewUrl
  if (externalUrl) {
    const url = new URL(externalUrl.trim())
    if (url.host === 'www.pga.com') {
      viewUrl = url.pathname
    }
  }

  const styles = useStyles()
  return (
    <ButtonCta
      className={styles.leaderboardButton}
      component={ButtonLink}
      variant="contained"
      color="primary"
      size="small"
      align="center"
      href={viewUrl || externalUrl || linkProps.href}
      dataAttrs={{
        'data-gtm-event-name': headline,
        'data-gtm-text': 'FULL LEADERBOARD',
        'data-testid': 'view-full-leaderboard',
      }}
    >
      View Full Leaderboard
    </ButtonCta>
  )
}

ButtonViewLeaderboard.propTypes = {
  headline: PropTypes.string,
  slug: PropTypes.string,
  externalUrl: PropTypes.string,
}

ButtonViewLeaderboard.defaultProps = {}

export default ButtonViewLeaderboard
