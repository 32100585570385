/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types'
import { Box, Typography, Container } from '@material-ui/core/'
import { DateTime } from 'luxon'
import DateRangeNew from '@components/atoms/date-range-new'
import IconCompleteNew from '@components/atoms/icons/icon-complete-new'
import IconDateFilledOutlinedAlt from '@components/atoms/icons/icon-date-filled-outlined-alt'
import IconLiveNew from '@components/atoms/icons/icon-live-new'
import ButtonViewLeaderboard from '@components/atoms/button-view-leaderboard'
import useStyles from './leaderboard.style-hook'

const LeaderboardHeadline = (props) => {
  const {
    headline,
    location,
    startDate,
    endDate,
    tourName,
    slug,
    status,
    dates,
    externalUrl,
  } = props

  const styles = useStyles()

  const liveText = 'Live'
  const upcomingText = 'Upcoming'
  let tourStatus = status || 'Complete'
  const now = DateTime.local()
  if (now < DateTime.fromISO(startDate)) tourStatus = upcomingText
  if ((now >= DateTime.fromISO(startDate)) && (now <= DateTime.fromISO(endDate))) tourStatus = liveText

  const liveLineText = tourName ? `${tourStatus} | ${tourName}` : tourStatus
  const locationLineText = `${location} | `
  const tourDates = dates || <DateRangeNew startDate={startDate} endDate={endDate} />

  return (
    <Container className={styles.headlineContainer}>
      <div>
        <Box mb={4} className={styles.liveLine}>
          {tourStatus === liveText ? <IconLiveNew viewBox="0 0 28 28" /> : tourStatus === upcomingText ? <IconDateFilledOutlinedAlt viewBox="0 0 28 28" /> : <IconCompleteNew viewBox="0 0 28 28" /> }
          <Typography variant="body1" className={styles.formInputs}>{liveLineText}</Typography>
        </Box>
        <Box className={styles.content}>
          <Box mb={1} pr={1} className={styles.headlineWrapper}>
            <Typography className={styles.headline} variant="h3" variantMapping={{ h3: 'h2' }}>{headline}</Typography>
            <Typography variant="h6" variantMapping={{ h6: 'h3' }}>{locationLineText}{tourDates}</Typography>
          </Box>
        </Box>
      </div>
      <ButtonViewLeaderboard headline={headline} slug={slug} externalUrl={externalUrl} />
    </Container>
  )
}

LeaderboardHeadline.propTypes = {
  headline: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  location: PropTypes.string,
  tourName: PropTypes.string,
  slug: PropTypes.string,
  dates: PropTypes.string,
  status: PropTypes.string,
  externalUrl: PropTypes.string,
}

LeaderboardHeadline.defaultProps = {}

export default LeaderboardHeadline
