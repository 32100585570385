import PropTypes from 'prop-types'

const DateRange = ({ startDate, endDate }) => {
  const start = new Date(startDate)
  const end = new Date(endDate)

  const startMonth = start.toLocaleDateString('en-us', { month: 'short' })
  const startDay = start.toLocaleDateString('en-us', { day: 'numeric' })
  const startYear = start.toLocaleDateString('en-us', { year: 'numeric' })
  const endMonth = end.toLocaleDateString('en-us', { month: 'short' })
  const endDay = end.toLocaleDateString('en-us', { day: 'numeric' })
  const endYear = end.toLocaleDateString('en-us', { year: 'numeric' })
  const sameDay = startDay === endDay
  const sameMonth = startMonth === endMonth
  const sameYear = startYear === endYear

  // Jul 2 – 15, 2022
  // Jul 31 – Aug 5, 2022
  // Jul 31, 2022 – Aug 5, 2023

  if (sameDay && sameMonth && sameYear) {
    return (
      <span>
        {/* eslint-disable-next-line max-len */}
        {startMonth} {startDay}{`, ${startYear}`}
      </span>
    )
  }
  return (
    <span>
      {/* eslint-disable-next-line max-len */}
      {startMonth} {startDay}{!sameYear && `, ${startYear}`} - {!sameMonth && endMonth} {endDay}, {endYear}
    </span>
  )
}

DateRange.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
}

export default DateRange
