import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: ({ background }) => ({
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    background: background && background.url ? `url(${background.url})` : theme.palette.secondary.main,
  }),
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '300px',
  },
  headline: {
    color: theme.palette.common.white,
    textAlign: 'center',
    paddingBottom: theme.spacing(4),
  },
  span: {
    color: theme.palette.gold.main,
  },
  signUpButton: {
    width: '190px',
    transition: '250ms',
  },
  modalContainer: {
    backgroundColor: 'rgba(0, 0, 0, .25)',
    height: '100vh',
    width: '100vw',
    '&>div': {
      '&>div': {
        width: '70%',
        height: '80%',
        maxHeight: '535px',
        minHeight: '510px',
        [theme.breakpoints.only('xs')]: {
          width: '90vw',
          height: '60vh',
          maxHeight: 'none',
        },
      },
    },
  },
  modal: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    border: '8px solid white',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    height: '100%',
    width: '100%',
  },
  closeIcon: {
    color: theme.palette.text.primary,
    transition: '250ms',
  },
  closeIconButton: {
    top: theme.spacing(1.5),
    right: theme.spacing(1.5),
    height: '50px',
    width: '50px',
    position: 'absolute',
  },
  topContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    top: theme.spacing(12),
    [theme.breakpoints.only('xs')]: {
      top: theme.spacing(8),
    },
  },
  modalTitle: {
    color: theme.palette.secondary.main,
    textAlign: 'center',
    paddingBottom: theme.spacing(4),
  },
  description: {
    textAlign: 'center',
    width: '90%',
  },
  formWrapper: {
    width: '90%',
    marginTop: theme.spacing(24),
  },
}))

export default useStyles
