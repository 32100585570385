/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import StayConnectedForm from '@components/stay-connected-form/index'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { Dialog, IconButton, Button } from '@material-ui/core'
import { useState } from 'react'
import { IconClose } from '@components/atoms/icons'
import useStyles from './stay-connected-secondary.styles'

const StayConnected = (props) => {
  const { signup } = props
  const {
    headline, background, emailHeadline, emailDescription, showSubscribe,
  } = signup
  const styles = useStyles({ background })
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [open, setOpen] = useState(false)

  const handleOpenModal = () => {
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
  }

  return (
    <Box className={styles.root}>
      <Container className={styles.container}>
        <Dialog
          fullScreen={isMobile}
          open={open}
          onClose={handleCloseModal}
          className={styles.modalContainer}
        >
          <Box className={styles.modal} data-testid="modal">
            <IconButton
              data-testid="close-modal"
              className={styles.closeIconButton}
              title="close"
              onClick={handleCloseModal}
            >
              <IconClose className={styles.closeIcon} />
            </IconButton>
            <Box className={styles.content}>
              <Box className={styles.topContent}>
                <Typography variant="h3" className={styles.modalTitle}>
                  {emailHeadline}
                </Typography>
                <Typography variant="body1" className={styles.description}>
                  {emailDescription}
                </Typography>
              </Box>
              <Box className={styles.formWrapper}>
                <StayConnectedForm
                  isMobile={isMobile}
                  emailDescription={emailDescription}
                  showSubscribe={showSubscribe}
                />
              </Box>
            </Box>
          </Box>
        </Dialog>
        <Typography variant="h4" variantMapping={{ h4: 'h2' }} className={styles.headline}>
          {headline || 'Stay up to date on all things PGA'}<span className={styles.span}>.</span>
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleOpenModal}
          className={styles.signUpButton}
        >
        Sign Up
        </Button>
      </Container>
    </Box>

  )
}

StayConnected.propTypes = {
  signup: PropTypes.shape({
    headline: PropTypes.string,
    background: PropTypes.shape({
      url: PropTypes.string,
    }),
    emailHeadline: PropTypes.string,
    emailDescription: PropTypes.string,
    showSubscribe: PropTypes.bool,
  }),
}

export default StayConnected
